import React, { useState ,useEffect} from 'react';
import { useFirebase } from "../context/SettingAction";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { z } from "zod";

import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";

const MAX_FILE_SIZE = 1024 * 1024 * 5;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const schema = z.object({
  enableCheckout: z.boolean(),
  enableExport: z.boolean(),
  enableBleed: z.boolean(),
  enableStorePickup: z.boolean(),
  enablePriceDisplay: z.boolean(),
  enableCopyrightNotice: z.boolean(),
});

const EditorSettings = ({ enableCheckout,
  enableBleed,
  enableExport,
  enablePriceDisplay,
  enableStorePickup,
  enableCopyrightNotice,}) => {
  
  const [loading, setLoading] = useState(false);
  const { updateEditorPreferences} = useFirebase();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      enableBleed,
      enableCheckout,
      enableExport,
      enablePriceDisplay,
      enableStorePickup,
      enableCopyrightNotice,
    },
  });

  const submitAction = async (data) => {
    setLoading(true);
    await updateEditorPreferences(data);
    setLoading(false);
    toast.success(`Updated Editor Preferences`);
  };
  useEffect(() => {

  },[enableBleed, enableCheckout,enableExport,enablePriceDisplay,enableStorePickup,enableCopyrightNotice,]);

  return (
    <Card className="max-w-[560px] sm:w-full">
      <CardHeader>
        <CardTitle>Editor Preferences</CardTitle>
        <CardDescription>
          Manage your the user facing editor settings here.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form
          className="grid gap-6"
          id="editor_preferences_form"
          onSubmit={form.handleSubmit(submitAction)}
        >
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="necessary" className="flex flex-col space-y-1">
              <span>Show Checkout Button</span>
            </Label>
            <Controller
              control={form.control}
              name="enableCheckout"
              render={({ field }) => (
                <Switch
                  id="enableCheckout"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="functional" className="flex flex-col space-y-1">
              <span>Show Export Button</span>
            </Label>
            <Controller
              control={form.control}
              name="enableExport"
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="performance" className="flex flex-col space-y-1">
              <span>Enable Bleed Guides</span>
            </Label>
            <Controller
              control={form.control}
              name="enableBleed"
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="performance" className="flex flex-col space-y-1">
              <span>Allow Store Pick Up</span>
            </Label>
            <Controller
              control={form.control}
              name="enableStorePickup"
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="performance" className="flex flex-col space-y-1">
              <span>Display Prices On Landing Page</span>
            </Label>
            <Controller
              control={form.control}
              name="enablePriceDisplay"
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex items-center justify-between space-x-2">
            <Label htmlFor="performance" className="flex flex-col space-y-1">
              <span>Show Copyright Notice</span>
            </Label>
            <Controller
              control={form.control}
              name="enableCopyrightNotice"
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <Button
          type="submit"
          form="editor_preferences_form"
          variant="outline"
          className="w-full save-currency-btn"
        >
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Save preferences
        </Button>
      </CardFooter>
    </Card>
  );
};

export default EditorSettings;
