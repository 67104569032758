import React, { useState, useEffect } from "react";
import { Loader2 } from "lucide-react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { getFirestore, collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Calendar } from 'primereact/calendar';

function OrderListing() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const uid = localStorage.getItem("uid");
  const storage = getStorage();
  const currencySymbol = localStorage.getItem('currencySymbol');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const db = getFirestore();
        let q = query(collection(db, "all_orders", uid, "orders"), orderBy("created_at", "desc"));
        
        if (startDate && endDate) {
          q = query(q, where("created_at", ">=", new Date(startDate)), where("created_at", "<=", new Date(endDate)));
        }
        
        const querySnapshot = await getDocs(q);
        
        const orderData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(orderData);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [uid, startDate, endDate]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const handleSortBy = (column) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    setSortedColumn(column);

    const sorted = [...orders].sort((a, b) => {
      if (column === "id") {
        return order === "asc" ? a.id.localeCompare(b.id) : b.id.localeCompare(a.id);
      } else if (column === "customer") {
        const nameA = `${a.firstName || ""} ${a.lastName || ""}`.toLowerCase();
        const nameB = `${b.firstName || ""} ${b.lastName || ""}`.toLowerCase();
        return order === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      } else if (column === "email") {
        const emailA = (a.email || "").toLowerCase();
        const emailB = (b.email || "").toLowerCase();
        return order === "asc" ? emailA.localeCompare(emailB) : emailB.localeCompare(emailA);
      } else if (column === "date") {
        return order === "asc" ? a.created_at.seconds - b.created_at.seconds : b.created_at.seconds - a.created_at.seconds;
      } else if (column === "amount") {
        return order === "asc" ? a.total_cost - b.total_cost : b.total_cost - a.total_cost;
      } else if (column === "status") {
        return order === "asc" ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
      }
      return 0;
    });

    setOrders(sorted);
  };

  const handleDownload = async (link) => {
    try {
      const storageRef = ref(storage, link);
      const downloadURL = await getDownloadURL(storageRef);
      const anchor = document.createElement("a");
      anchor.href = downloadURL;
      anchor.download = "file";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: 'America/New_York'
    };
    return date.toLocaleString("en-US", options);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const filteredOrders = orders.filter(
    (order) =>
      (selectedStatus === "All" || order.status === selectedStatus) &&
      (order.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      `${(order.firstName || "").toLowerCase()} ${(order.lastName || "").toLowerCase()}`.includes(searchQuery.toLowerCase()) ||
      `${(order.email || "").toLowerCase()}`.includes(searchQuery.toLowerCase()))
  );
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Order Listing</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                    <div className="col-sm-6">

                    <h4 className="card-title mb-4">Orders</h4>
                    </div>
                    <div className="col-sm-6">
                    <h6 className="text-center"><b>Orders Filter By Date Range</b></h6>  
                    </div>
                    </div>
                    <div className="mb-3">
                      <div className="row order-listing-row">
                        <div className="col-lg-3">
                          <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Search by Order ID or Customer Name"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                          />
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label calendor-label">Filter By Order Status:</label>
                          <select
                            className="form-select mb-2"
                            value={selectedStatus}
                            onChange={handleStatusChange}
                          >
                            <option value="All">All</option>
                            <option value="PENDING">Pending</option>
                            <option value="COMPLETED">Completed</option>
                          </select>
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label calendor-label">Start Date:</label>
                        <Calendar
                          value={startDate}
                          onChange={(e) => setStartDate(e.value)}
                          dateFormat="yy-mm-dd"
                          inputClassName="form-control"
                          placeholder="Select Start Date"
                          className="mb-2 calender-input-size"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label calendor-label">End Date:</label>
                        <Calendar
                          value={endDate}
                          onChange={(e) => setEndDate(e.value)}
                          dateFormat="yy-mm-dd"
                          inputClassName="form-control"
                          placeholder="Select End Date"
                          className="mb-2 calender-input-size"
                        />
                      </div>
                      </div>
                    </div>
                    {loading ? (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    ) : filteredOrders.length === 0 ? (
                      <p>No records found</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th
                                className="align-middle"
                                onClick={() => handleSortBy("id")}
                                style={{ cursor: "pointer" }}
                              >
                                Order ID <i className="fas fa-sort-down"></i>
                                {sortedColumn === "id" && (
                                  <i
                                    className={`fas fa-sort-${
                                      sortOrder === "asc" ? "up" : "down"
                                    }`}
                                  ></i>
                                )}
                              </th>
                              <th
                                className="align-middle"
                                onClick={() => handleSortBy("customer")}
                                style={{ cursor: "pointer" }}
                              >
                                Customer <i className="fas fa-sort-down"></i>
                                {sortedColumn === "customer" && (
                                  <i
                                    className={`fas fa-sort-${
                                      sortOrder === "asc" ? "up" : "down"
                                    }`}
                                  ></i>
                                )}
                              </th>
                              <th
                                className="align-middle"
                                onClick={() => handleSortBy("email")}
                                style={{ cursor: "pointer" }}
                              >
                                Email <i className="fas fa-sort-down"></i>
                                {sortedColumn === "email" && (
                                  <i
                                    className={`fas fa-sort-${
                                      sortOrder === "asc" ? "up" : "down"
                                    }`}
                                  ></i>
                                )}
                              </th>
                              <th
                                className="align-middle"
                                onClick={() => handleSortBy("status")}
                                style={{ cursor: "pointer" }}
                              >
                                Status <i className="fas fa-sort-down"></i>
                                {sortedColumn === "status" && (
                                  <i
                                    className={`fas fa-sort-${
                                      sortOrder === "asc" ? "up" : "down"
                                    }`}
                                  ></i>
                                )}
                              </th>
                              <th
                                className="align-middle"
                                onClick={() => handleSortBy("date")}
                                style={{ cursor: "pointer" }}
                              >
                                Date <i className="fas fa-sort-down"></i>
                                {sortedColumn === "date" && (
                                  <i
                                    className={`fas fa-sort-${
                                      sortOrder === "asc" ? "up" : "down"
                                    }`}
                                  ></i>
                                )}
                              </th>
                              <th
                                className="align-middle"
                                onClick={() => handleSortBy("amount")}
                                style={{ cursor: "pointer" }}
                              >
                                Amount <i className="fas fa-sort-down"></i>
                                {sortedColumn === "amount" && (
                                  <i
                                    className={`fas fa-sort-${
                                      sortOrder === "asc" ? "up" : "down"
                                    }`}
                                  ></i>
                                )}
                              </th>
                              <th className="align-middle">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentOrders.map((order) => (
                              <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>{`${order.firstName} ${order.lastName}`}</td>
                                <td>{order.email}</td>
                                <td>{capitalizeFirstLetter(order.status)}</td>
                                <td>{order.created_at ? formatDate(order.created_at.seconds) : "N/A"}</td>
                                <td>{order.total_cost ? `${currencySymbol}${order.total_cost}` : "N/A"}</td>
                                <td className="btn-group">
                                  <button className="btn btn-primary btn-sm me-2 download-red-button" onClick={() => handleDownload(order.link)}>
                                    <i className="fa fa-download" aria-hidden="true"></i> Download
                                  </button>
                                  <Link to={`/order/${order.id}`} className="btn btn-primary btn-sm viewmore-eye-button">
                                    <i className="fa fa-eye" aria-hidden="true"></i> View
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  {filteredOrders.length > 0 && (
                    <div className="row">
                      <div className="col">
                        <nav>
                          <ul className="pagination justify-content-center">
                            {Array.from(
                              {
                                length: Math.ceil(filteredOrders.length / ordersPerPage),
                              },
                              (_, i) => (
                                <li
                                  key={i}
                                  className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                                >
                                  <button className="page-link" onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                  </button>
                                </li>
                              )
                            )}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrderListing;
