import { clsx } from 'clsx';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { twMerge } from 'tailwind-merge';


dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function formattedDate(date) {
  return dayjs.utc(date).local().format('LLL');
}
